<template>
	<v-row class="d-flex justify-center height">
		<v-col cols="8" md="4" class="d-flex align-center justify-center flex-column">
			<v-img :src="illustration" max-height="400" contain />
			<div class="d-flex flex-column text-center align-center">
				<div class="display-2 mt-10">How did you get here?</div>
				<div class="mt-3 mb-6">Sorry we can't seem to find the page you're looking for.</div>
				<v-btn @click="$router.push('/login')" elevation="0"  height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm" color="#6F70FF"> Back to login</v-btn>
			</div>
		</v-col>
	</v-row>
</template>

<script>

export default {
    data() {
        return {
            illustration: require("@/assets/img/illustrations/undraw_page_not_found_re_e9o6.svg")
        };
    }
};
</script>

<style>
.height {
	max-height: 100vh;
}
</style>
